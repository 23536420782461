<template>
    <div class="personalinfoContainerx">
        <div class="window">
            <img src="../../assets/infoBg.png" class="windowImg1"/>
            <div class="windowContent">

                <img v-if="imageUrl != ''" :src="imageUrl" class="personIcon">
                <img v-if="imageUrl==''" src="../../assets/default_avatar.png" class="personIcon">


                <el-upload
                        class="uploadIcon"
                        action="111"
                        :show-file-list = "false"
                        :before-upload="beforeAvatarUpload">
                        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                        <button class="uploadIconBtn">上传头像</button>
                        
                </el-upload>


                <div class="nameItem">{{user.name}}</div>
                <div class="accountItem">{{user.account_name}}</div>
                <div class="emailItem">联系邮箱: {{user.email}}</div>


                <div class="lineItem"></div>

                <div class="eventItem">
                    参与挑战项目:
                </div>
                <div class="eventItem1">
                    <div v-for=" (x,index) in all_events" :key="index"  class="eventInfo">
                        
                        {{x +'&nbsp;&nbsp;&nbsp;'}}
                        <!-- {{'参赛项目:'+x +'&nbsp;&nbsp;&nbsp;'+   '完赛状态: 完结'}} -->
                    </div>
                </div>

               
            </div>
        </div>
    </div>
</template>

<script>
import {getUser,uploadPic,updateUserAvatar} from '../../api/index'
import {getUserId,getUserType} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            user:{},
            user_type:getUserType(),
            imageUrl:'',
            all_events:[]
        }
    },
    mounted(){
        this.getUser()
    },
    methods:{
        getUser(){
            getUser(getUserId(),getUserType()).then(res=>{
                if(res.data.code===0){
                    this.user = res.data.data
                    console.log(1111,this.user)
                    if (this.user.avatar_url != ''){
                        this.imageUrl = this.user.avatar_url;
                    }else{
                        this.imageUrl = '';
                    }

                    this.all_events = this.user.all_events

                }
            })
        },
        beforeAvatarUpload(file){
            const param = new FormData()
            param.append('file', file)
            param.append('function', 'uploadPic')

            uploadPic(param).then(res=>{
                if(res.data.code===0){
                    this.imageUrl = res.data.data
                    this.updateUserAvatar(this.imageUrl)
                }
            })
        },
        updateUserAvatar(url){
            updateUserAvatar(getUserId(),url).then(res=>{
                this.getUser()
            })
        },
        handleEventName(event){
            if(event){
                let event_array = event.split(" ")
                return event_array[0]
            }else{
                return ''
            }
        }
    }
}
</script>

<style lang="less">
.personalinfoContainerx{
    width: 90%;
    margin-left: 40px;
    margin-top: 50px;
    .window{
        margin-top: 1vh;
        position: relative;
        
        .windowContent{
            width: 100%;
            height: 100%;
            overflow: hidden;
           
           
        }
    }
    .windowImg1{
            width: 100%;
            height: 60vh;
            left: 0;
            top:0;
            z-index: 0;
            position: absolute;
        }
     .itemLine{
        // position: absolute;
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 5vh auto;
        .item{
            width: 45%;
            padding-bottom: 10px;
            border-bottom: 2px solid #072D76;
            color: #072D76;
            font-size: 2.2vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }
    }
    .personIcon{
        position: absolute;
                display: flex;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background-color: #00359F;
        margin-left: 30px;
        margin-top: 20px;
        font-family: Montserrat-SemiBold;
    }
    .uploadIcon{
        position: absolute;
        // width: 80px;
        // height: 20px;
        border-radius: 7.5px;
        background-color: #00359F;
        margin-left: 30px;
        margin-top: 120px;
        align-items: center;
        font-family: Montserrat-SemiBold;
    }
    .uploadIconBtn{
        // position: absolute;
        border: none;
        width: 80px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #00359F;
        margin-left: 0px;
        margin-top: 0px;
        // margin: 0px;
        text-align: center;
        color: white;
        font-family: Montserrat-SemiBold;
    }
    .nameItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 30px;
        color: #00116A;
        font-size: 20px;
        font-family: Montserrat-SemiBold;

    }
    .accountItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 60px;
        color: #00379E;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }

    .emailItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 90px;
        color: #00379E;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .lineItem{
        position: absolute;

        margin-left: 140px;
        margin-top: 130px;
        width: 300px;
        height: 1px;
        background-color: #00379E;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .eventItem{
        position: absolute;
        margin-left: 140px;
        margin-top: 145px;
        color: #00379E;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .eventItem1{
        position: absolute;
        margin-left: 140px;
        margin-top: 165px;
        color: #00379E;
        font-size: 18px;
        font-family: Montserrat-SemiBold;
    }
    .eventInfo{
        margin-top: 10px;
        margin-left: 0px;
        color: #00379E;
        font-size: 13px;
        font-family: Montserrat-SemiBold;
    }
    
}


</style>